import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  CSSObject,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Drawer as MuiDrawer,
  Slide,
  Stack,
  TextField,
  Theme,
  Typography,
  styled,
  Tooltip,
  Collapse,
  Chip,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { getAuth } from 'firebase/auth';
import { forwardRef, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Messages, UserPlan, UserRole } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { BrandContext } from '../../context/BrandContext';
import { PopupNotificationContext } from '../../context/PopupNotificationContext';
import { GET_BRANDS } from '../../graphql/queries';
import { RoutesNames } from '../../types';
import { formatDate, getFullName } from '../../utils';
import Avatar from '../Avatar/Avatar';
import BrandModal from '../BrandComponents/BrandModal';
import DrawerListItem from '../DrawerListItem/DrawerListItem';
import LinkedinPageSelector from '../SocialMediaAccounts.tsx/LinkedinPageSelector';
import { ChangeContentBoardButton } from './ChangeContentBoardButton';
import { NavBarMobile } from './NavbarMobile';
import { useTranslation } from 'react-i18next';
import { SET_READ_APP_NOTIFICATION } from '../../graphql/mutations';
import { timeAgo } from '../../utils';
import { DOWNLOAD_DATA_REQUEST } from '../../graphql/mutations';
import { SnackbarContext } from '../../context/SnackbarContext';
import { GET_POST } from '../../graphql/queries';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 10px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const DrawerDesktop = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface Props {
  currentPage: RoutesNames;
  isSmallDevice?: boolean;
  isMobile?: boolean;
  openNavbar: boolean;
  setOpenNavbar: (open: boolean) => void;
}

const NavBar = ({
  currentPage,
  isSmallDevice,
  isMobile,
  openNavbar,
  setOpenNavbar,
}: Props) => {
  const { t } = useTranslation();
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [isDownloadPopupOpen, setDownloadPopupOpen] = useState(false);
  const { setSuccessMessage, setErrorMessage } = useContext(SnackbarContext);
  const { user, setUser, changeBrand, refetchCurrentUser } = useContext(AuthContext);
  const [openSubmenuCreation, setOpenSubmenuCreation] = useState(true);
  const [openSubmenuPlanning, setOpenSubmenuPlanning] = useState(false);
  const location = useLocation();
  const [notifications, setNotifications] = useState(
    user?.notifications?.appMessages || [],
  );
  const { handleRenewAccess, handleBrandSelected, setLoadingUpdateBrandAccount } =
    useContext(BrandContext);
  const {
    handleSaveLinkedinCompanyCredentials,
    setLinkedinPageSelectorOpen,
    pages,
    modalNewBrand,
    handleCloseModal,
    handleOpenModal,
    openModalCompany,
    setOpenModalCompany,
  } = useContext(BrandContext);

  const [setReadAppNotification] = useMutation(SET_READ_APP_NOTIFICATION);
  const [fetchPost] = useLazyQuery(GET_POST);
  const [downloadDataRequest] = useMutation(DOWNLOAD_DATA_REQUEST, {
    variables: {
      userId: user?._id || '',
      shellId: user?.shell?._id || '',
      userEmail: user?.email || '',
    },
    onCompleted: (data) => {
      if (data.downloadDataRequest?.success) {
        setSuccessMessage(t('Data download request sent successfully'));
      } else {
        setErrorMessage(t('Error making the request'));
      }
    },
  });

  //This use effect for now doesnt work because the websocket doesnt make this, because of IA and socket problems that we had earlier
  // useEffect(() => {
  //   if (userSocket) {
  //     userSocket.on(WebSocketEventType.UserChange, (data) => {
  //       if (data?.documentKey._id === user?._id) {
  //         refetchCurrentUser();
  //       }
  //     });
  //   }
  // }, [userSocket, user?._id, refetchCurrentUser]);

  useEffect(() => {
    if (user?.notifications?.appMessages) {
      const sortedNotifications = [...user.notifications.appMessages].sort(
        (a, b) =>
          new Date(b?.message?.date).getTime() - new Date(a?.message?.date).getTime(),
      );
      setNotifications(sortedNotifications);
    }
  }, [user?.notifications?.appMessages]);

  const markAllNotificationsAsRead = async () => {
    const response = await setReadAppNotification({
      variables: { allNotifications: true },
    });

    if (response.data?.setReadAppNotification?.success) {
      // Update all notifications to `read: true`
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification
            ? { ...notification, message: { ...notification.message, read: true } }
            : notification,
        ),
      );
      refetchCurrentUser();
    } else {
      // Handle error case
      setErrorMessage('Failed to mark all notifications as read');
    }
  };

  const markAsRead = async (
    messageId: string,
    postId: string,
    openPost: boolean,
    isCurrentlyRead: boolean,
  ) => {
    try {
      if (!openPost) {
        // Toggle the read/unread status locally for immediate effect
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification?.message?._id === messageId
              ? {
                  ...notification,
                  message: { ...notification.message, read: !isCurrentlyRead },
                }
              : notification,
          ),
        );

        // Update the notification status in the backend
        await setReadAppNotification({ variables: { messageId } });
      } else {
        // Fetch the post to check if it exists
        const { data, error } = await fetchPost({ variables: { postId } });

        if (error || !data?.post) {
          // Post doesn't exist or there are errors
          setErrorMessage(t('The requested post does not exist.'));
        } else {
          // Post exists, navigate to the post page
          navigate(`/post/${postId}`);

          // Mark notification as read in the backend if not already read
          if (!isCurrentlyRead) {
            await setReadAppNotification({ variables: { messageId } });
            // Mark as read (or toggle if needed) locally
          }
        }
      }
    } catch (error) {
      setErrorMessage(t('An error occurred while processing your request.'));
    }
  };

  const {
    popupMessages,
    handleClose,
    handleReview,
    handleReviewLater,
    brandsToReconnect,
    handleReconnectLinkedinReminder,
  } = useContext(PopupNotificationContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElAvatar, setAnchorElAvatar] = useState<null | HTMLElement>(null);
  const [searchInput, setSearchInput] = useState<string>('');

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchInput(value);
  };

  const handleNotificationsOpen = () => {
    refetchCurrentUser();
    setNotificationsOpen(true);
  };

  const handleNotificationsClose = () => {
    setNotificationsOpen(false);
    refetchCurrentUser();
  };

  const handleToggleSubmenuCreation = () => {
    setOpenSubmenuCreation(!openSubmenuCreation);
  };

  const handleToggleSubmenuPlanning = () => {
    setOpenSubmenuPlanning(!openSubmenuPlanning);
  };

  const { data: brandsData } = useQuery(GET_BRANDS, {
    variables: {},
    skip:
      !user ||
      (!user.role.includes(UserRole.BrandManager) &&
        !user.role.includes(UserRole.LimitedBrandManager)),
    fetchPolicy: 'network-only',
  });

  const brands =
    brandsData?.getBrands.filter((brand) =>
      brand?.name.toLowerCase().includes(searchInput.toLowerCase()),
    ) || [];

  const userBrand = brands.find((brand) => brand?._id === user?.brandId);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSearchInput('');
  };

  const handleOpenDownloadPopup = () => {
    setDownloadPopupOpen(true);
  };

  const handleCloseDownloadPopup = () => {
    setDownloadPopupOpen(false);
  };

  const handleSelectBrand = (brandId: string) => {
    handleCloseMenu();
    changeBrand(brandId);
  };

  const onClickDrawerItem = (route: RoutesNames) => {
    if (anchorElAvatar) setAnchorElAvatar(null);
    navigate(route);
  };

  const onClickLogout = () => {
    setUser(null);
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    const auth = getAuth();
    auth.signOut();
    navigate('/login');
  };

  const createItems = [
    ...(!user?.role?.includes(UserRole.BrandManager) &&
    !user?.role?.includes(UserRole.LimitedBrandManager)
      ? [
          {
            text: t('Content Board'),
            iconName: 'GridView',
            route: '',
          },
        ]
      : []),
    ...(user?.plan?.includes(UserPlan.Starter)
      ? [
          {
            text: t('Creation'),
            iconName: 'AutoAwesome',
            isDropdownCreation: true,
          },
        ]
      : []),
    ...(user?.plan?.includes(UserPlan.Starter)
      ? [
          {
            text: t('Planning'),
            iconName: 'CalendarToday',
            isDropdownPlanning: true,
          },
        ]
      : []),
    ...(user?.role?.includes(UserRole.InsightsViewer) &&
    !user?.plan?.includes(UserPlan.Starter)
      ? [
          {
            text: t('Inspiration'),
            iconName: 'Insights',
            route: 'insights',
          },
        ]
      : []),
    ...(!user?.role?.includes(UserRole.BrandManager) &&
    !user?.role?.includes(UserRole.LimitedBrandManager) &&
    !user?.plan?.includes(UserPlan.Starter)
      ? [
          {
            text: t('Calendar'),
            iconName: 'CalendarToday',
            route: 'calendar',
          },
        ]
      : []),

    {
      text: t('Analytics'),
      iconName: 'Analytics',
      route: 'analytics',
    },
  ];

  const urlMeeting =
    'https://meetings-eu1.hubspot.com/meetings/rafael-calle?uuid=dd9aa13d-1ee3-4f53-9f6a-12d0e658da53';

  const createItemsBlock = [
    ...(user?.plan?.includes(UserPlan.Starter)
      ? [
          {
            text: t('Employee ambassador'),
            iconName: 'Campaign',
            route: urlMeeting,
          },
        ]
      : []),
    ...(user?.plan?.includes(UserPlan.Starter)
      ? [
          {
            text: t('Client ambassador'),
            iconName: 'RecordVoiceOver',
            route: urlMeeting,
          },
        ]
      : []),
    ...(user?.plan?.includes(UserPlan.Starter)
      ? [
          {
            text: t('Brands management'),
            iconName: 'WorkspacePremium',
            route: urlMeeting,
          },
        ]
      : []),
    ...(user?.plan?.includes(UserPlan.Starter)
      ? [
          {
            text: t('Users management'),
            iconName: 'Groups',
            route: urlMeeting,
          },
        ]
      : []),
    ...(user?.plan?.includes(UserPlan.Starter)
      ? [
          {
            text: t('Social Listening'),
            iconName: 'ConnectWithoutContact',
            route: urlMeeting,
          },
        ]
      : []),
  ];

  const manageCreateItems = [
    ...(user?.role?.includes(UserRole.BrandManager) ||
    user?.role?.includes(UserRole.LimitedBrandManager)
      ? [
          {
            text: t('Content Manager'),
            iconName: 'Collections',
            route: 'manage-team',
          },
        ]
      : []),
    ...(user?.role?.includes(UserRole.AdvocacyManager)
      ? [
          {
            text: t('Advocacy'),
            iconName: 'ConnectWithoutContact',
            route: 'advocacy',
          },
        ]
      : []),
    ...(user?.role?.includes(UserRole.BrandManager) ||
    user?.role?.includes(UserRole.LimitedBrandManager)
      ? [
          {
            text: t('Brands'),
            iconName: 'Group',
            route: 'brands',
          },
        ]
      : []),
    ...(user?.role?.includes(UserRole.GroupMembersEditor)
      ? [
          {
            text: t('Members'),
            iconName: 'Tune',
            route: 'groups-members',
          },
        ]
      : []),
  ];

  const analysisCreateItems = [
    ...(user?.role?.includes(UserRole.BrandManager) ||
    user?.role?.includes(UserRole.LimitedBrandManager)
      ? [
          {
            text: t('Brands Activity'),
            iconName: 'Diversity3',
            route: 'brands-activity',
          },
          {
            text: t('Brands Performance'),
            iconName: 'Diversity1',
            route: 'brands-performance',
          },
          {
            text: t('Brands Content Table'),
            iconName: 'Star',
            route: 'top-content',
          },
        ]
      : []),
  ];

  const adminItems = [
    ...(user?.role?.includes(UserRole.Admin)
      ? [
          {
            text: t('Client'),
            iconName: 'PersonAdd',
            route: 'clients',
          },
          {
            text: t('Users'),
            iconName: 'Group',
            route: 'users',
          },
        ]
      : []),
    ...(user?.role?.includes(UserRole.Admin)
      ? [
          {
            text: t('Content Admin'),
            iconName: 'Collections',
            route: 'content-manager',
          },
        ]
      : []),
  ];

  const buildPopupMessage = (message: Messages) => {
    return (
      <Dialog
        disableEscapeKeyDown
        key={message._id}
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(message)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{message.title}</DialogTitle>
        <DialogContent>
          {message.image && (
            <CardMedia
              component={message.image.startsWith('video') ? 'video' : 'img'}
              controls
              image={message.image}
              sx={{
                marginBottom: 1,
                height: '250x',
                width: '250px',
                display: 'flex',
                marginX: 'auto',
              }}
              data-testid="post-card-media"
            />
          )}
          <DialogContentText id="alert-dialog-slide-description">
            {message.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(message)}>{t('Dismiss')}</Button>
          <Button variant="outlined" onClick={() => handleReviewLater(message)}>
            {t('Remind me later')}
          </Button>
          <Button variant="contained" onClick={() => handleReview(message)}>
            {t('Open')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const reconnectLinkedInPopup = brandsToReconnect.length > 0 && (
    <Dialog
      disableEscapeKeyDown
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {}}
    >
      <DialogTitle>
        {brandsToReconnect.length > 1
          ? t('LinkedInConnectionExpirePlural')
          : t('LinkedInConnectionExpireSingular')}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {brandsToReconnect.map((brand) => (
            <Stack direction="row" alignItems="center" spacing={2} key={brand._id}>
              <Avatar avatar={brand.account?.avatar || ''} name={brand.name} />
              <Stack direction="column" sx={{ flexGrow: 1 }}>
                <Typography variant="body1">{brand.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {t('Access expires on')} {formatDate(new Date(brand.account?.exp))}
                </Typography>
              </Stack>
              <Button
                variant="outlined"
                onClick={() => {
                  handleBrandSelected(brand);
                  handleRenewAccess(brand.account?.type!, window.location.pathname);
                }}
              >
                {t('Renew access')}
              </Button>
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReconnectLinkedinReminder}>{t('Remind me later')}</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{
          transform: isMobile ? 'translate(0px, 50px)' : 'translate(25px, 10px)',
          '& .MuiPaper-root': {
            borderRadius: '8px',
            ...(isMobile
              ? {
                  width: '100%',
                  height: '50vh',
                }
              : {}),
          },
        }}
        elevation={10}
        MenuListProps={{
          sx: {
            height: 'auto',
            maxHeight: isMobile ? '100vh' : 410,
            width: isMobile ? '100%' : 245,
            minHeight: 200,
          },
        }}
      >
        <Typography
          sx={{
            paddingLeft: '20px',
            color: 'rgba(194, 11, 99, 1)',
            marginY: '0.5rem',
            fontSize: '14px',
            fontWeight: '700',
          }}
        >
          {t('Change brand')}
        </Typography>

        <Box width="100%" display="flex" justifyContent="center">
          <TextField
            sx={{
              width: '90%',
            }}
            placeholder={t('Search')}
            onChange={onSearch}
            InputProps={{
              style: {
                height: '40px',
                borderRadius: '8px',
              },
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </Box>

        <Box
          sx={{
            paddingLeft: '1.5rem',
            background: '#F0F0F0',
            marginY: 1,
            '&:last-child td, &:last-child th': { border: 0 },
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
            gap: 1,
          }}
          onClick={handleOpenModal}
        >
          <AddIcon
            sx={{
              fontSize: '24px',
            }}
          />
          <Typography fontSize={14}>{t('Create new brand')}</Typography>
        </Box>

        {userBrand && (
          <Box
            display="flex"
            key={userBrand?._id}
            width="100%"
            paddingLeft="1.5rem"
            alignItems="center"
            gap={1}
            marginTop="0.3rem"
            onClick={() => {
              handleSelectBrand(userBrand?._id!);
            }}
            sx={{
              cursor: 'pointer',
              backgroundColor:
                user?.brandSelectedId === userBrand?._id ? '#F0F0F0' : 'white',
              height: '56px',
              '&:hover': {
                backgroundColor: '#F0F0F0',
              },
            }}
          >
            <Avatar
              name={userBrand?.name || ''}
              avatar={userBrand?.account?.avatar || undefined}
              backgroundColor={userBrand?.backgroundColor || undefined}
              size={40}
            />
            <Typography fontSize="14px" fontWeight="bold">
              {userBrand.name}
            </Typography>
          </Box>
        )}
        <Divider variant="fullWidth" sx={{ marginY: 1 }} />
        {brands
          .filter((brand) => brand?._id !== user?.brandId)
          .map((brand) => (
            <Box
              display="flex"
              key={brand?._id}
              width="100%"
              paddingLeft="1.5rem"
              alignItems="center"
              gap={1}
              marginTop="0.3rem"
              onClick={() => {
                handleSelectBrand(brand?._id!);
              }}
              sx={{
                cursor: 'pointer',
                backgroundColor:
                  user?.brandSelectedId === brand?._id ? '#F0F0F0' : 'white',
                height: '56px',
                '&:hover': {
                  backgroundColor: '#F0F0F0',
                },
              }}
            >
              <Avatar
                name={brand?.name || ''}
                backgroundColor={brand?.backgroundColor || undefined}
                avatar={brand?.account?.avatar || undefined}
                size={40}
              />
              <Typography
                fontSize={user?.brandSelectedId === brand?._id ? '15px' : '14px'}
                sx={{
                  color: user?.brandSelectedId === brand?._id ? '#C20B63' : 'black',
                  fontWeight: user?.brandSelectedId === brand?._id ? 700 : 'normal',
                }}
              >
                {brand?.name}
              </Typography>
            </Box>
          ))}
      </Menu>

      {isMobile ? (
        <NavBarMobile
          brandSelect={user?.brandSelected || null}
          userSelectBackgroundColor={''}
          onBrandSelect={(brand, background) => handleSelectBrand(brand._id)}
          onChangeContentBoardClick={(e) => {
            setAnchorEl(anchorEl ? null : e.currentTarget);
          }}
          anchorElement={anchorEl}
          currentPage={currentPage}
          onClickLogout={onClickLogout}
        />
      ) : (
        <DrawerDesktop
          variant="permanent"
          open={openNavbar}
          PaperProps={{
            sx: {
              boxShadow: '0px 10px 24px 4px #4F4F4F1F',
              borderRadius: '0px 9px 9px 0px !important',
              border: 'none',
            },
          }}
        >
          <DrawerHeader>
            <Box
              display={openNavbar ? 'flex' : 'none'}
              flexDirection="row"
              alignItems="center"
              gap={1}
            >
              <img
                src={
                  user?.shell?.whiteLabel
                    ? user.shell.logo || '/images/magnettu_logo.svg'
                    : '/images/magnettu_logo.svg'
                }
                alt="magnettu logo"
                width="130px"
              />
            </Box>

            <IconButton
              color="primary"
              onClick={() => setOpenNavbar(!openNavbar)}
              sx={{
                marginTop: '5px',
                marginX: !openNavbar ? 'auto' : '0px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {openNavbar ? (
                <KeyboardDoubleArrowLeftIcon />
              ) : (
                <KeyboardDoubleArrowRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Stack justifyContent="space-between" height="100%">
            <Stack>
              <List
                sx={{
                  ...(user?.role.includes(UserRole.BrandManager) ||
                  user?.role.includes(UserRole.LimitedBrandManager)
                    ? { backgroundColor: '#F0F0F0' }
                    : {}),
                  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                }}
              >
                {(user?.role?.includes(UserRole.BrandManager) ||
                  user?.role?.includes(UserRole.LimitedBrandManager)) && (
                  <ListItem disablePadding sx={{ width: '90%', marginX: 'auto', mb: 2 }}>
                    {!brands.length ? (
                      <Button
                        variant="outlined"
                        sx={{
                          width: '100%',
                          backgroundColor: '#FFF',
                          height: '35px',
                          borderRadius: '25px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        onClick={handleOpenModal}
                      >
                        <Typography
                          fontSize="14px"
                          fontWeight={700}
                          sx={{
                            textAlign: 'center',
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                          }}
                        >
                          {t('Create brand')}
                        </Typography>
                        <AddIcon
                          sx={{
                            fontSize: '24px',
                            marginLeft: 'auto',
                          }}
                        />
                      </Button>
                    ) : (
                      <ChangeContentBoardButton
                        variant={openNavbar ? 'standard' : 'compact'}
                        onClick={(e) => {
                          setAnchorEl(anchorEl ? null : e.currentTarget);
                        }}
                        anchorElement={anchorEl}
                        brand={user.brandSelected || null}
                      />
                    )}
                  </ListItem>
                )}
                {openNavbar &&
                  !user?.role?.includes(UserRole.BrandManager) &&
                  !user?.role?.includes(UserRole.LimitedBrandManager) &&
                  !user?.plan?.includes(UserPlan.Starter) && (
                    <Typography
                      fontSize="11px"
                      fontWeight={600}
                      marginLeft="1rem"
                      marginBottom="1rem"
                      marginTop="0.5rem"
                    >
                      {t('CONTENT')}
                    </Typography>
                  )}

                {createItems.map(
                  ({ text, iconName, route, isDropdownCreation, isDropdownPlanning }) => {
                    if (isDropdownCreation) {
                      return (
                        <div key={text}>
                          <DrawerListItem
                            text={openNavbar ? text : ''}
                            iconName={iconName}
                            onClick={handleToggleSubmenuCreation} // ✅ Toggle submenu
                            isSmallDevice={isSmallDevice}
                          >
                            {openSubmenuCreation ? <ExpandLess /> : <ExpandMore />}
                          </DrawerListItem>

                          {/* ✅ Expanding Submenu */}
                          <Collapse in={openSubmenuCreation} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding sx={{ pl: 0 }}>
                              {' '}
                              {/* Adjust padding */}
                              <DrawerListItem
                                text={openNavbar ? t('Create a post from scratch') : ''}
                                onClick={() => navigate('/?newPost=true')}
                                iconName={openNavbar ? '' : 'Article'}
                                isSmallDevice={isSmallDevice}
                                textSize={12}
                              />
                              {user?.role?.includes(UserRole.InsightsViewer) && (
                                <DrawerListItem
                                  text={openNavbar ? t('Inspiration Library') : ''}
                                  onClick={() => navigate('/insights')}
                                  iconName={openNavbar ? '' : 'BatchPrediction'}
                                  isSmallDevice={isSmallDevice}
                                  textSize={12}
                                  isActive={currentPage === 'insights'}
                                />
                              )}
                              <DrawerListItem
                                text={openNavbar ? t('Suggest a post with AI') : ''}
                                onClick={() => navigate('/?suggestAI=true')}
                                iconName={openNavbar ? '' : 'AutoAwesome'}
                                isSmallDevice={isSmallDevice}
                                textSize={12}
                              />
                              <DrawerListItem
                                text={openNavbar ? t('AI Content Assistant') : ''}
                                onClick={() => navigate('/?buildingSection=true')}
                                isSmallDevice={isSmallDevice}
                                iconName={openNavbar ? '' : 'Assistant'}
                                textSize={12}
                                isActive={
                                  new URLSearchParams(location.search).get(
                                    'buildingSection',
                                  ) === 'true'
                                }
                              >
                                {openNavbar && (
                                  <Chip
                                    label="New"
                                    size="small"
                                    color="secondary"
                                    sx={{
                                      backgroundColor: '#97A4BD', // Custom secondary color
                                      color: 'white', // White text
                                      fontWeight: '400',
                                      borderRadius: '4px', // Specific border-radius
                                      height: '18px', // Exact height
                                      minWidth: '25px', // Ensures space for "New"
                                      fontSize: '9px', // Small font
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      paddingX: '2px', // Increased padding to prevent text cutoff
                                      whiteSpace: 'nowrap', // Prevents text wrapping
                                      overflow: 'visible', // Prevents truncation
                                    }}
                                  />
                                )}
                              </DrawerListItem>
                              <DrawerListItem
                                text={openNavbar ? t('Create carousels') : ''}
                                onClick={() => navigate('/?buildingSection2=true')}
                                isSmallDevice={isSmallDevice}
                                iconName={openNavbar ? '' : 'DynamicFeed'}
                                textSize={12}
                                isActive={
                                  new URLSearchParams(location.search).get(
                                    'buildingSection2',
                                  ) === 'true'
                                }
                              >
                                {openNavbar && (
                                  <Chip
                                    label="New"
                                    size="small"
                                    color="secondary"
                                    sx={{
                                      backgroundColor: '#97A4BD', // Custom secondary color
                                      color: 'white', // White text
                                      fontWeight: '400',
                                      borderRadius: '4px', // Specific border-radius
                                      height: '18px', // Exact height
                                      minWidth: '25px', // Ensures space for "New"
                                      fontSize: '9px', // Small font
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      paddingX: '2px', // Increased padding to prevent text cutoff
                                      whiteSpace: 'nowrap', // Prevents text wrapping
                                      overflow: 'visible', // Prevents truncation
                                    }}
                                  />
                                )}
                              </DrawerListItem>
                              <DrawerListItem
                                text={openNavbar ? t('Generate an image with AI') : ''}
                                onClick={() => navigate('/?buildingSection3=true')}
                                iconName={openNavbar ? '' : 'PhotoFilter'}
                                isSmallDevice={isSmallDevice}
                                textSize={12}
                                isActive={
                                  new URLSearchParams(location.search).get(
                                    'buildingSection3',
                                  ) === 'true'
                                }
                              >
                                {openNavbar && (
                                  <Chip
                                    label="New"
                                    size="small"
                                    color="secondary"
                                    sx={{
                                      backgroundColor: '#97A4BD', // Custom secondary color
                                      color: 'white', // White text
                                      fontWeight: '400',
                                      borderRadius: '4px', // Specific border-radius
                                      height: '18px', // Exact height
                                      minWidth: '25px', // Ensures space for "New"
                                      fontSize: '9px', // Small font
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      paddingX: '2px', // Increased padding to prevent text cutoff
                                      whiteSpace: 'nowrap', // Prevents text wrapping
                                      overflow: 'visible', // Prevents truncation
                                    }}
                                  />
                                )}
                              </DrawerListItem>
                              <DrawerListItem
                                text={
                                  openNavbar ? t('Create a post based on a prompt') : ''
                                }
                                iconName={openNavbar ? '' : 'TipsAndUpdates'}
                                onClick={() => navigate('/?inputAI=true')}
                                isSmallDevice={isSmallDevice}
                                textSize={12}
                              />
                            </List>
                          </Collapse>
                        </div>
                      );
                    } else if (isDropdownPlanning) {
                      return (
                        <div key={text}>
                          <DrawerListItem
                            text={openNavbar ? text : ''}
                            onClick={handleToggleSubmenuPlanning}
                            iconName={iconName}
                            isSmallDevice={isSmallDevice}
                          >
                            {openSubmenuPlanning ? <ExpandLess /> : <ExpandMore />}
                          </DrawerListItem>

                          {/* ✅ Expanding Submenu */}

                          <Collapse in={openSubmenuPlanning} timeout="auto" unmountOnExit>
                            <List
                              component="div"
                              disablePadding
                              sx={{
                                pl: 0,
                                mt: 0.7,
                              }}
                            >
                              <DrawerListItem
                                text={openNavbar ? t('Calendar') : ''}
                                onClick={() => navigate('/calendar')}
                                isSmallDevice={isSmallDevice}
                                iconName={openNavbar ? '' : 'CalendarMonth'}
                                isActive={currentPage === 'calendar'}
                                textSize={12}
                              />
                            </List>
                          </Collapse>
                        </div>
                      );
                    }
                    return (
                      <DrawerListItem
                        key={text}
                        text={openNavbar ? text : ''}
                        iconName={iconName}
                        onClick={() => onClickDrawerItem(route as RoutesNames)}
                        isActive={(() => {
                          if (route === '') {
                            // Ensure the page is truly empty and doesn't match named routes
                            return currentPage === '' && window.location.search === '';
                          }
                          return currentPage === route;
                        })()}
                        isSmallDevice={isSmallDevice}
                      />
                    );
                  },
                )}

                {createItemsBlock.map(({ text, iconName, route }) => (
                  <DrawerListItem
                    key={text}
                    text={openNavbar ? text : ''}
                    iconName={iconName}
                    onClick={() => {
                      window.open(route, '_blank');
                    }}
                    isActive={currentPage === route}
                    isSmallDevice={isSmallDevice}
                    isTrial
                  />
                ))}

                {user?.brandSelected?.contentCreation?.requireContent && (
                  <DrawerListItem
                    tooltip={t('You can send us content requests through Whatsapp')}
                    text={openNavbar ? 'WhatsApp' : ''}
                    iconName={'WhatsApp'}
                    onClick={() =>
                      window.open(
                        `https://wa.me/34663937183?text=${encodeURIComponent(
                          t(
                            `Hola magnettu soy ${user.firstName} ${user.lastName} y quería pedirte que me ayudes para que en la marca de ${user.brandSelected?.name} ...`,
                          ),
                        )}`,
                        '_blank',
                      )
                    }
                    isSmallDevice={isSmallDevice}
                  />
                )}

                {(user && user.customMenus && user.customMenus?.length !== 0
                  ? user.customMenus!!.map((menu) => ({
                      text: menu?.title as string,
                      iconName: menu?.icon as string,
                      route: menu?.url,
                    }))
                  : []
                ).map(({ text, iconName, route }) => (
                  <DrawerListItem
                    key={text}
                    text={openNavbar ? text : ''}
                    iconName={iconName}
                    onClick={() => {
                      if (route) window.open(route, '_blank');
                    }}
                    isActive={currentPage === route}
                    isSmallDevice={isSmallDevice}
                    rotateIcon={text === 'Groups & Members'}
                  />
                ))}
              </List>
              <List>
                {manageCreateItems.length > 0 && openNavbar && (
                  <Typography
                    fontSize="11px"
                    fontWeight={600}
                    marginLeft="1rem"
                    marginBottom="1rem"
                    marginTop="1rem"
                  >
                    {t('MANAGE')}
                  </Typography>
                )}
                {manageCreateItems.map(({ text, iconName, route }) => (
                  <DrawerListItem
                    key={text}
                    text={openNavbar ? text : ''}
                    iconName={iconName}
                    onClick={() => onClickDrawerItem(route as RoutesNames)}
                    isActive={currentPage === route}
                    isSmallDevice={isSmallDevice}
                  />
                ))}

                {analysisCreateItems.length > 0 && openNavbar && (
                  <Typography
                    fontSize="11px"
                    fontWeight={600}
                    marginLeft="1rem"
                    marginBottom="1rem"
                    marginTop="1rem"
                  >
                    {t('ANALYSIS')}
                  </Typography>
                )}
                {analysisCreateItems.map(({ text, iconName, route }) => (
                  <DrawerListItem
                    key={text}
                    text={openNavbar ? text : ''}
                    iconName={iconName}
                    onClick={() => onClickDrawerItem(route as RoutesNames)}
                    isActive={currentPage === route}
                    isSmallDevice={isSmallDevice}
                  />
                ))}
                {user?.role?.includes(UserRole.BrandManager) && (
                  <DrawerListItem
                    text={t('Download Data')}
                    iconName="Download"
                    onClick={handleOpenDownloadPopup}
                  />
                )}

                <Dialog open={isDownloadPopupOpen} onClose={handleCloseDownloadPopup}>
                  <DialogTitle>{t('Download Data')}</DialogTitle>
                  <DialogContent>
                    <Typography>
                      {t(
                        'Would you like to receive the complete data for all your posts? If you proceed, youll receive an Excel file with all the data in your email within minutes.',
                      )}
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDownloadPopup}>{t('Cancel')}</Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setDownloadPopupOpen(false);
                        downloadDataRequest();
                      }}
                    >
                      {t('Ok')}
                    </Button>
                  </DialogActions>
                </Dialog>

                {adminItems.length > 0 && openNavbar && (
                  <Typography
                    fontSize="11px"
                    fontWeight={600}
                    marginLeft="1rem"
                    marginBottom="1rem"
                    marginTop="1rem"
                  >
                    {t('MAGNETTU ADMIN')}
                  </Typography>
                )}
                {adminItems.map(({ text, iconName, route }) => (
                  <DrawerListItem
                    key={text}
                    text={openNavbar ? text : ''}
                    iconName={iconName}
                    onClick={() => onClickDrawerItem(route as RoutesNames)}
                    isActive={currentPage === route}
                    isSmallDevice={isSmallDevice}
                  />
                ))}
              </List>
            </Stack>
            <Stack>
              <DrawerListItem
                text={openNavbar ? t('Notifications') : ''}
                iconName={'Notifications'}
                onClick={handleNotificationsOpen}
              >
                {(user?.notifications?.appMessages?.filter((msg) => !msg?.message?.read)
                  ?.length || 0) > 0 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '10px',
                      right: openNavbar ? '16px' : '10px', // Adjust position based on whether the navbar is open or closed
                      width: '20px',
                      height: '20px',
                      backgroundColor: '#30527C',
                      color: '#fff',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
                    }}
                  >
                    {
                      user?.notifications?.appMessages?.filter(
                        (msg) => !msg?.message?.read,
                      ).length
                    }
                  </Box>
                )}
              </DrawerListItem>

              <MuiDrawer
                anchor="right" // Aligns the drawer vertically like the image
                open={notificationsOpen}
                onClose={handleNotificationsClose}
                PaperProps={{
                  sx: {
                    width: '400px', // Adjust width for desired appearance
                  },
                }}
              >
                <Box display="flex" flexDirection="column" height="100%">
                  {/* Header */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                    borderBottom="1px solid #ddd"
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {t('Notifications')}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      {notifications.some(
                        (notification) => !notification?.message?.read,
                      ) && (
                        <Tooltip title={t('Mark all as read')} placement="left">
                          <IconButton onClick={markAllNotificationsAsRead}>
                            <CheckBoxIcon />
                          </IconButton>
                        </Tooltip>
                      )}

                      <IconButton onClick={handleNotificationsClose}>
                        <KeyboardDoubleArrowRightIcon />
                      </IconButton>
                    </Box>
                  </Box>

                  {/* Notifications List */}
                  <Box flexGrow={1} overflow="auto">
                    {notifications.length ? (
                      notifications.map((notification) => (
                        <Box
                          key={notification?.message?._id}
                          display="flex"
                          alignItems="flex-start"
                          p={2}
                          borderBottom="1px solid rgb(21, 37, 112)"
                          sx={{
                            cursor: 'pointer',
                            backgroundColor: notification?.message?.read
                              ? 'white'
                              : 'rgba(48, 82, 124, 0.2)',
                            position: 'relative', // For absolute positioning of the IconButton
                            '&:hover .icon-button': {
                              display: 'flex', // Show the button on hover
                            },
                          }}
                          onClick={() =>
                            markAsRead(
                              notification?.message?._id || '',
                              notification?.message?.postId || '',
                              true,
                              notification?.message?.read || false,
                            )
                          }
                        >
                          <Stack spacing={0.5} width="100%">
                            <Typography fontWeight="bold">
                              {notification?.message?.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              dangerouslySetInnerHTML={{
                                __html: notification?.message?.message || '',
                              }}
                            />
                            <Typography variant="caption" color="gray">
                              {timeAgo(new Date(notification?.message?.date), t)}
                            </Typography>
                          </Stack>
                          <Tooltip
                            title={
                              notification?.message?.read
                                ? t('Mark as unread')
                                : t('Mark as read')
                            }
                            placement="left"
                          >
                            <IconButton
                              className="icon-button" // Class for hover targeting
                              sx={{
                                display: 'none', // Initially hidden
                                position: 'absolute', // Position it on the top right corner
                                right: 16, // Adjust spacing from the right edge
                                top: 11, // Adjust spacing from the top edge
                              }}
                              onClick={(event) => {
                                event.stopPropagation(); // Prevent the parent `onClick` from firing
                                markAsRead(
                                  notification?.message?._id || '',
                                  notification?.message?.postId || '',
                                  false, // Do not open post
                                  notification?.message?.read || false,
                                );
                              }}
                            >
                              {notification?.message?.read ? (
                                <CheckBoxOutlineBlankIcon />
                              ) : (
                                <CheckBoxIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ))
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <Typography color="textSecondary">
                          {t('No notifications')}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </MuiDrawer>

              <DrawerListItem
                text={openNavbar ? t('Help') : ''}
                iconName={'HelpOutline'}
                onClick={() =>
                  window.open(
                    'https://magnettu.notion.site/Ayuda-a7a72297741a4acca98d456591ed31e5',
                    '_blank',
                  )
                }
                isSmallDevice={isSmallDevice}
              />
              <Divider variant="fullWidth" sx={{ marginY: 1 }} />
              <List sx={{ paddingTop: 0 }}>
                <ListItem
                  key={user?._id}
                  disablePadding
                  sx={{ padding: 0, width: '90%', marginX: 'auto' }}
                >
                  <ListItemButton
                    sx={{ borderRadius: '9px' }}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      setAnchorElAvatar(anchorElAvatar ? null : event.currentTarget);
                    }}
                  >
                    <ListItemIcon sx={{ marginLeft: '6px' }}>
                      <Avatar
                        name={user?.firstName || user?.email || ''}
                        lastName={user?.lastName || undefined}
                        avatar={user?.avatar || undefined}
                      />
                    </ListItemIcon>
                    {openNavbar && (
                      <ListItemText sx={{ opacity: !openNavbar ? 0 : 1 }}>
                        <Stack>
                          <Typography variant="body1" fontSize={14}>
                            {getFullName(user).length > 20
                              ? `${getFullName(user).substring(0, 17)}...`
                              : getFullName(user)}
                          </Typography>
                          {user?.activeTrial && new Date(user?.trialEnd) < new Date() ? (
                            <Typography variant="caption">{`${t(
                              'Trial ends',
                            )} ${formatDate(new Date(user?.trialEnd))}`}</Typography>
                          ) : (
                            <Typography variant="caption">
                              {t('Active License')}
                            </Typography>
                          )}
                        </Stack>
                      </ListItemText>
                    )}
                  </ListItemButton>
                </ListItem>
              </List>
            </Stack>
          </Stack>
          {!!popupMessages.length && popupMessages.map(buildPopupMessage)}
          {reconnectLinkedInPopup}

          <LinkedinPageSelector
            open={openModalCompany}
            onClose={() => {
              setOpenModalCompany(false);
              setLoadingUpdateBrandAccount(false);
            }}
            onSubmit={(page) => {
              handleSaveLinkedinCompanyCredentials(page);
              setLinkedinPageSelectorOpen(null);
              setOpenModalCompany(false);
            }}
            pages={pages}
          />

          <Dialog
            open={modalNewBrand}
            onClose={handleCloseModal}
            PaperProps={{
              sx: {
                width: '800px',
                maxWidth: '800px !important',
              },
            }}
          >
            <BrandModal />
          </Dialog>
          <Menu
            anchorEl={anchorElAvatar}
            open={Boolean(anchorElAvatar)}
            onClose={() => setAnchorElAvatar(null)}
            PaperProps={{
              sx: {
                overflowY: 'auto',
                width: 245,
                height: 'auto',
                boxShadow: '0px 11px 14px 0px rgba(0, 0, 0, 0.13)',
                borderRadius: '5px',
                marginLeft: openNavbar ? '16rem' : '5rem',
                marginTop: '-2rem',
              },
            }}
          >
            <Stack>
              <List>
                <DrawerListItem
                  text={t('Settings')}
                  iconName={'Settings'}
                  onClick={() => onClickDrawerItem('settings')}
                />
                <DrawerListItem
                  text={t('Logout')}
                  iconName={'Logout'}
                  onClick={() => onClickLogout()}
                />
              </List>
            </Stack>
          </Menu>
        </DrawerDesktop>
      )}
    </>
  );
};

export default NavBar;
