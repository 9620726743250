import { Stack, Divider, TextField, Button, IconButton, Paper } from '@mui/material';
import { useContext, useState, useRef } from 'react';
import { DeviceContext } from '../../context/DeviceContext';
import { MessagesTeam } from '../../__generated__/graphql';
import { getFullName } from '../../utils';
import CommentItem from './CommentItem';
import { useTranslation } from 'react-i18next';
import EmojiSelector from '../SelectorComponents/EmojiSelector';
import { InsertEmoticon } from '@mui/icons-material';

interface CommentsProps {
  messages: MessagesTeam[];
  handleSendMessage: (message: string) => void;
  writing: boolean;
}

const Comments: React.FC<CommentsProps> = ({ messages, handleSendMessage, writing }) => {
  const { t } = useTranslation();
  const { isMobile } = useContext(DeviceContext);
  const [message, setMessage] = useState('');
  const ref = useRef<HTMLDivElement>(null);
  const [emojiComponentOpen, setEmojiComponentOpen] = useState(false);
  const handleEmojiComponentOpen = () => {
    setEmojiComponentOpen(true);
  };
  const handleEmojiInputChange = (emoji: string) => {
    setMessage((message) => message + emoji);
  };
  const anchorRef = useRef(null);

  if (isMobile) return null;

  return (
    <>
      <Divider sx={{ my: 1 }} />
      <Stack direction="column" spacing={2}>
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            gap: 1,
            padding: '10px',
            width: '100%',
            borderRadius: 2,
            border: '1px solid #C1C1C1',
          }}
        >
          <TextField
            inputRef={ref}
            fullWidth
            value={message}
            size="small"
            multiline
            maxRows={5}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={t('Share your feedback here...')}
          />
          <IconButton
            data-testid="emoji-button"
            onClick={handleEmojiComponentOpen}
            ref={anchorRef}
          >
            <InsertEmoticon />
          </IconButton>

          <EmojiSelector
            open={emojiComponentOpen}
            anchorElement={anchorRef.current}
            onClose={() => setEmojiComponentOpen(false)}
            onChange={(emoji) => handleEmojiInputChange(emoji)}
          />
          <Button
            disabled={writing || !message}
            onClick={() => {
              handleSendMessage(message);
              setMessage('');
            }}
            sx={{
              backgroundColor: '#6E7794', // Updated primary button color
              color: 'white',
              marginLeft: 1,
              '&:hover': {
                backgroundColor: '#5F687F', // Slightly darker shade for hover effect
              },
              '&.Mui-disabled': {
                backgroundColor: 'rgba(0, 0, 0, 0.12)', // Disabled background color
                color: 'rgba(255, 255, 255, 0.9)', // Adjusted disabled text color for better contrast
              },
            }}
          >
            {t('Save')}
          </Button>
        </Paper>
        {[...messages].reverse().map((msg, index) => {
          let avatar = null;
          let name = '';
          if (msg.userOrBrand?.__typename === 'User') {
            avatar = msg.userOrBrand.avatar;
            name = getFullName(msg.userOrBrand);
          } else if (msg.userOrBrand?.__typename === 'Brand') {
            avatar = msg.userOrBrand.account?.avatar;
            name = msg.userOrBrand.name;
          }

          return (
            <CommentItem
              key={'comment-' + index}
              userOrBrand={msg.userOrBrand}
              text={msg.content}
              avatar={avatar || ''}
              time={msg.time}
              name={name || 'Unknown'}
            />
          );
        })}
      </Stack>
    </>
  );
};

export default Comments;
