import { Stack, Typography, Paper } from '@mui/material';
import { UserOrBrand } from '../../__generated__/graphql';
import Avatar from '../Avatar/Avatar';
import { timeAgoWithDate } from '../../utils';
import { useTranslation } from 'react-i18next';

const CommentItem = ({
  userOrBrand,
  text,
  time,
  name,
  avatar,
}: {
  userOrBrand?: UserOrBrand | null;
  avatar: string | null;
  text: string;
  time: Date;
  name: string | null;
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: 2,
        width: '100%',
        borderRadius: 2,
        border: '1px solid #C1C1C1',
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center" width="100%">
        {/* Avatar */}
        <Avatar name={name || ''} avatar={avatar ? avatar : undefined} />

        {/* Name & Timestamp Container */}
        <Stack direction="row" alignItems="center" spacing={1} flex={1}>
          <Typography variant="body2" fontWeight="bold">
            {name}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {(() => {
              const { formattedTime, isOldDate, isJustNow } = timeAgoWithDate(
                time,
                t,
                i18n.language,
              );
              if (isOldDate) return formattedTime; // Show only formatted date for old timestamps
              if (isJustNow) return formattedTime; // Show "Just now" without "ago"
              return i18n.language === 'en'
                ? `${formattedTime} ${t('ago')}`
                : `${t('ago')} ${formattedTime}`;
            })()}
          </Typography>
        </Stack>
      </Stack>

      {/* Message Text - Aligned and Reduced Margin */}
      <Typography
        variant="body2"
        sx={{
          ml: '0px', // Align with avatar
          mt: 0, // Reduce top margin to minimize space
          wordBreak: 'break-word',
          
        }}
      >
        {text}
      </Typography>
    </Paper>
  );
};

export default CommentItem;
